var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "calendar-app" },
    [
      _c("div", { staticClass: "modal fade", attrs: { id: "program-modal" } }, [
        _c("div", { staticClass: "modal-dialog" }, [
          _vm.selectedEventInfo
            ? _c("div", { staticClass: "modal-content" }, [
                _c("div", { staticClass: "modal-header" }, [
                  _c("h5", { staticClass: "modal-title" }, [
                    _vm._v(
                      _vm._s(
                        _vm.selectedEventInfo.event.extendedProps.block.name
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "close",
                      attrs: { type: "button", "data-dismiss": "modal" },
                    },
                    [_vm._v("×")]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "modal-body" }, [
                  _c("ul", { staticClass: "list-unstyled" }, [
                    _vm.selectedEventInfo.event.extendedProps.block.category
                      ? _c("li", [
                          _c("strong", [_vm._v("Kategorie: ")]),
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.selectedEventInfo.event.extendedProps.block
                                  .category
                              ) +
                              "\n                        "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedEventInfo.event.getResources().length > 0
                      ? _c("li", [
                          _c("strong", [_vm._v("Místnost: ")]),
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.selectedEventInfo.event.getResources()[0]
                                  .title
                              ) +
                              "\n                        "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("li", [
                      _c("strong", [_vm._v("Obsazenost: ")]),
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm.selectedEventInfo.event.extendedProps.block
                              .capacity
                              ? _vm.selectedEventInfo.event.extendedProps
                                  .attendeesCount +
                                  "/" +
                                  _vm.selectedEventInfo.event.extendedProps
                                    .block.capacity
                              : _vm.selectedEventInfo.event.extendedProps
                                  .attendeesCount
                          ) +
                          "\n                            "
                      ),
                      _vm.selectedEventInfo.event.extendedProps.block
                        .alternatesAllowed
                        ? _c("span", [
                            _vm._v(
                              "(" +
                                _vm._s(
                                  _vm.selectedEventInfo.event.extendedProps
                                    .alternatesCount
                                ) +
                                " náhradníků)"
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.selectedEventInfo.event.extendedProps.block.lectors
                    .length > 0
                    ? _c(
                        "div",
                        [
                          _c("h6", [_vm._v("Lektoři")]),
                          _vm._v(" "),
                          _vm._l(
                            _vm.selectedEventInfo.event.extendedProps.block
                              .lectors,
                            function (lector) {
                              return _c("div", [
                                _c("b", [_vm._v(_vm._s(lector.name))]),
                                _vm._v(" "),
                                _c("p", [
                                  lector.photo
                                    ? _c("img", {
                                        staticClass: "float-left mr-3 mb-3",
                                        attrs: {
                                          src: _vm.basePath + lector.photo,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(lector.about) +
                                      "\n                            "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "clearfix" }),
                              ])
                            }
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("h6", [_vm._v("Popis")]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.selectedEventInfo.event.extendedProps.block.perex
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.selectedEventInfo.event.extendedProps.block
                          .description
                      ),
                    },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.registerProgramsAllowed,
                        expression: "registerProgramsAllowed",
                      },
                    ],
                    staticClass: "modal-footer",
                  },
                  [
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              !(
                                _vm.selectedEventInfo.event.extendedProps
                                  .userAttends ||
                                _vm.selectedEventInfo.event.extendedProps
                                  .userAlternates
                              ) &&
                              !(
                                _vm.selectedEventInfo.event.extendedProps.block
                                  .alternatesAllowed &&
                                _vm.selectedEventInfo.event.extendedProps
                                  .occupied
                              ),
                            expression:
                              "!(selectedEventInfo.event.extendedProps.userAttends || selectedEventInfo.event.extendedProps.userAlternates) && !(selectedEventInfo.event.extendedProps.block.alternatesAllowed && selectedEventInfo.event.extendedProps.occupied)",
                          },
                        ],
                        staticClass: "btn btn-sm btn-success",
                        attrs: {
                          disabled:
                            _vm.selectedEventInfo.event.extendedProps.blocked ||
                            _vm.selectedEventInfo.event.extendedProps
                              .occupied ||
                            !_vm.selectedEventInfo.event.extendedProps.paid,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleAttendEvent()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                        Přihlásit se na program\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              !(
                                _vm.selectedEventInfo.event.extendedProps
                                  .userAttends ||
                                _vm.selectedEventInfo.event.extendedProps
                                  .userAlternates
                              ) &&
                              _vm.selectedEventInfo.event.extendedProps.block
                                .alternatesAllowed &&
                              _vm.selectedEventInfo.event.extendedProps
                                .occupied,
                            expression:
                              "!(selectedEventInfo.event.extendedProps.userAttends || selectedEventInfo.event.extendedProps.userAlternates) && (selectedEventInfo.event.extendedProps.block.alternatesAllowed && selectedEventInfo.event.extendedProps.occupied)",
                          },
                        ],
                        staticClass: "btn btn-sm btn-success",
                        attrs: {
                          disabled:
                            _vm.selectedEventInfo.event.extendedProps.blocked ||
                            !_vm.selectedEventInfo.event.extendedProps.paid,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleAttendEvent()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                        Přihlásit se na program jako náhradník\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.selectedEventInfo.event.extendedProps
                                .userAttends ||
                              _vm.selectedEventInfo.event.extendedProps
                                .userAlternates,
                            expression:
                              "selectedEventInfo.event.extendedProps.userAttends || selectedEventInfo.event.extendedProps.userAlternates",
                          },
                        ],
                        staticClass: "btn btn-sm btn-danger",
                        attrs: {
                          disabled:
                            _vm.selectedEventInfo.event.extendedProps.block
                              .autoRegistered,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleUnattendEvent()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                        Odhlásit se z programu\n                    "
                        ),
                      ]
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.notRegisteredMandatoryPrograms > 0,
              expression: "notRegisteredMandatoryPrograms > 0",
            },
          ],
          staticClass: "alert alert-warning alert-forever",
        },
        [
          _vm._v(
            "\n        Máte nepřihlášené povinné programy (v kalendáři červeně). Prosíme, zapište si je.\n    "
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row mb-2" }, [
        _c("div", { staticClass: "col-auto mr-auto mb-1" }, [
          _c("div", { staticClass: "btn-group" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-sm btn-secondary",
                class: { active: _vm.initialView === "timeGridSeminar" },
                on: {
                  click: function ($event) {
                    return _vm.handleChangeView("timeGridSeminar")
                  },
                },
              },
              [_vm._v("\n                    Na výšku\n                ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-sm btn-secondary",
                class: {
                  active: _vm.initialView === "resourceTimelineSeminar",
                },
                on: {
                  click: function ($event) {
                    return _vm.handleChangeView("resourceTimelineSeminar")
                  },
                },
              },
              [_vm._v("\n                    Na šířku\n                ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-sm btn-secondary",
                class: { active: _vm.initialView === "listSeminar" },
                on: {
                  click: function ($event) {
                    return _vm.handleChangeView("listSeminar")
                  },
                },
              },
              [_vm._v("\n                    Seznam\n                ")]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.initialView === "resourceTimelineSeminar",
                  expression: "initialView === 'resourceTimelineSeminar'",
                },
              ],
              staticClass: "btn-group",
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-secondary",
                  attrs: { id: "btnPrev" },
                  on: {
                    click: function ($event) {
                      return _vm.handlePrev()
                    },
                  },
                },
                [_c("span", { staticClass: "fc-icon fc-icon-chevron-left" })]
              ),
              _vm._v(" "),
              _c("button", {
                staticClass: "btn btn-sm btn-secondary",
                attrs: { id: "btnTitle", disabled: "" },
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-secondary",
                  attrs: { id: "btnNext" },
                  on: {
                    click: function ($event) {
                      return _vm.handleNext()
                    },
                  },
                },
                [_c("span", { staticClass: "fc-icon fc-icon-chevron-right" })]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(1),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-auto mb-1 mr-n2" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.loading > 0,
                  expression: "loading > 0",
                },
              ],
              staticClass: "spinner float-left mr-2",
            },
            [_c("span", { staticClass: "fa fa-spinner fa-pulse fa-2x" })]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "notifications float-left mr-2" }, [
            _vm.message
              ? _c(
                  "div",
                  {
                    staticClass: "alert",
                    class: "alert-" + _vm.message.type,
                    staticStyle: { padding: "4px 8px", margin: "0" },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.message.text) +
                        "\n                "
                    ),
                  ]
                )
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("FullCalendar", {
        ref: "fullCalendar",
        staticStyle: { visibility: "hidden" },
        attrs: { id: "calendar", options: _vm.calendarOptions },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "modal fade", attrs: { id: "help-modal" } },
      [
        _c("div", { staticClass: "modal-dialog" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c("h5", { staticClass: "modal-title" }, [_vm._v("Nápověda")]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: { type: "button", "data-dismiss": "modal" },
                },
                [_vm._v("×")]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c("p", [
                _vm._v(
                  "Kliknutím na program se zobrazí možnost přihlášení/odhlášení a další informace o programu."
                ),
              ]),
              _vm._v(" "),
              _c("h6", [_vm._v("Typy programů")]),
              _vm._v(" "),
              _c("ul", { staticClass: "list-group text-white" }, [
                _c("li", { staticClass: "list-group-item bg-success" }, [
                  _vm._v("Programy, které máte přihlášené."),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "list-group-item bg-warning" }, [
                  _vm._v("Programy, které máte přihlášené jako náhradník."),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "list-group-item bg-primary" }, [
                  _vm._v("Programy, na které se lze přihlásit."),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "list-group-item bg-danger" }, [
                  _vm._v("Programy, které jsou povinné."),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "list-group-item bg-secondary" }, [
                  _vm._v(
                    "Programy, na které se nelze přihlásit (máte přihlášený stejný program, je obsazený nebo jej nemáte zaplacený)."
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "btn btn-sm btn-secondary",
        attrs: { "data-toggle": "modal", "data-target": "#help-modal" },
      },
      [
        _c("i", { staticClass: "fa fa-question-circle" }),
        _vm._v(" Nápověda\n            "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }